import { MText } from "src/ui/MText"

export function PageTitle({
  children,
  style,
  classNames,
  ...props
}: {
  children: React.ReactNode
  style?: React.CSSProperties
  classNames?: string
}) {
  return (
    <MText variant="heading1" classNames={classNames} style={style} {...props}>
      {children}
    </MText>
  )
}
